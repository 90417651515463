<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canGenerate"
        variant="primary"
        @click="openModal()"
      >
        Generate new
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :columns="columns"
      :filters="filters"
      :resource="resource"
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <gift-card-keys-generate-editor :id="entity" @submit="handleSubmit" />
    </b-modal>
  </div>
</template>

<script>
import {
  VxCellBadges,
  VxCellDate,
  VxTable
} from '@/components/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { giftCards, passDataToResource, useResource } from '@/services/resources'
import { ref } from '@vue/composition-api'
import { useModalEditor } from '@/services/modal'
import { getRouteIdParam } from '@/router'
import { filterTypes } from '@/services/table'
import GiftCardKeysGenerateEditor from '@/views/gift-cards/GiftCardKeysGenerateEditor'
import { BModal } from 'bootstrap-vue'

export default {
  name: 'GiftCardKeys',
  components: {
    VxTable,
    VxPageTitleActions,
    VxButton,
    VxCellDate,
    VxCellBadges,
    GiftCardKeysGenerateEditor,
    BModal
  },
  setup () {
    const tableRef = ref(null)
    const entityId = getRouteIdParam()

    const { modalIsOpen, openModal, closeModal } = useModalEditor()

    const { can: canGenerate } = useResource(giftCards.generate)

    const modalTitle = 'Generate New Key'

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    const resource = passDataToResource(giftCards.getKeys, {
      requestParams: {
        urlParams: { id: entityId }
      }
    })

    const columns = [
      { key: 'id', sortable: true },
      { key: 'code' },
      { key: 'status', component: VxCellBadges },
      {
        key: 'created_at',
        sortable: true,
        component: VxCellDate
      },
      {
        key: 'updated_at',
        sortable: true,
        component: VxCellDate
      }
    ]

    const filters = [
      { key: 'code' },
      {
        key: 'status',
        type: filterTypes.serverSelect,
        resource: giftCards.statuses,
        searchable: false
      }
    ]

    return {
      tableRef,
      resource,
      columns,
      filters,
      canGenerate,

      entity: entityId,
      handleSubmit,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal
    }
  }
}
</script>
