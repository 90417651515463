<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.amount"
      :rules="rules.amount"
      name="amount"
      label="Amount"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Generate
    </vx-button>
  </vx-form>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { VxForm, VxInput } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { giftCards, passDataToResource } from '@/services/resources'

export default {
  name: 'GiftCardKeysGenerateEditor',
  components: {
    VxForm,
    VxInput,
    VxButton
  },
  props: {
    id: {
      type: [Number, String],
      default: null
    }
  },
  setup ({ id }, { emit }) {
    const formValue = reactive({
      amount: 1
    })

    const frontToBackMapper = ({ amount }) => {
      return {
        amount: Number(amount)
      }
    }

    const formResource = passDataToResource(giftCards.generate, {
      requestParams: { urlParams: { id } },
      frontToBackMapper
    })

    const rules = {
      amount: {
        required: true,
        min_value: 1,
        max_value: 1000
      }
    }

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res)
      }
    }

    return {
      formResource,
      formValue,
      rules,
      handleSubmit
    }
  }
}
</script>
